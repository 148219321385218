import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { SimpleStoreClient } from "../libs/common_ts/SimpleStoreClient";
import { DefaultAccountScreen } from "../libs/components/screens/DefaultAccountScreen";
import { DefaultOAuthHandlerScreen } from "../libs/components/screens/DefaultOAuthHandler";
import { DefaultPageInProgress } from "../libs/components/screens/DefaultPageInProgress";
import { TAppConfig, setAppConfig } from "../libs/dweb/DAppCommand";
import { DAppInit } from "../libs/dweb/DAppInit";
import { DAppWrapper } from "../libs/dweb/DAppWrapper";
import { DHelmet } from "../libs/dweb/DHelmet";
import { DPageLoadingWrapper } from "../libs/dweb/DPageLoadingWrapper";
import { DResponsiveWindow } from "../libs/dweb/DResponsiveWindow";
import { DashBoard } from "./pages/DashboardPage";
import { LandingPage } from "./pages/LandingPage";
import { TestPage } from "./pages/TestPage";

// Step1: Init
let appConfig: TAppConfig = {
  app_id: "grodok_algoverse",
  app_name: "Grodok AlgoVerse",
  primary_color: '#5d4d03',
  dark: false,
  app_slogan: 'Algo trading platform by Grodok',
  app_subtext: 'Algo trading platform by Grodok',
  logo: require('./focus/favicon_io/android-chrome-512x512.png'),
}
SimpleStoreClient.Get().init(appConfig)
setAppConfig(appConfig);

export function BasePageNavigation({ children }: { children: any }) {
  return <DPageLoadingWrapper style={{ marginTop: 20 }} stickyNotification>
    <DResponsiveWindow>
      {children}
    </DResponsiveWindow>
  </DPageLoadingWrapper>
}


function App() {
  return (
    <RecoilRoot>

      <DHelmet config={{ page_title: "Home Page" }} />
      <DAppWrapper>
        <BrowserRouter>
          <DAppInit appConfig={appConfig} />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/account" element={<DefaultAccountScreen />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/test" element={<TestPage />} />
            <Route path="/oauth/:provider" element={<DefaultOAuthHandlerScreen />} />
            <Route path="*" element={<DefaultPageInProgress />} />
          </Routes>
        </BrowserRouter>
      </DAppWrapper>
    </RecoilRoot>
  );
}

export default App;
