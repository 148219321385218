import { CSSProperties } from "react";
import { DHtml } from "./DHtml";

export const DMarquee = ({ text, style }: { text: string, style?: CSSProperties }) => {
    const marqueeStyles = {
        width: '100%',
        overflow: 'hidden',
    };

    const contentStyles = {
        display: 'inline-block',
        //whiteSpace: 'nowrap',
        animation: 'marquee-animation 10s linear infinite',
    };

    return (
        <div style={{ ...marqueeStyles, ...style }}>
            <DHtml style={contentStyles} html={text} />
        </div>
    );
}