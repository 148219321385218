import { LoadingButton } from "@mui/lab"
import { TVoidCalBack, dcolors } from "corexxx"
import { CSSProperties } from "react"

export const DChip = ({ text, loading, onClick, style, href }: { href?: string, text: string, onClick?: TVoidCalBack, loading?: boolean, style?: CSSProperties }) => {
    return <LoadingButton
        disabled={!onClick && !href}
        size="small"
        loading={loading} onClick={onClick} style={{
            borderRadius: 15,
            textTransform: 'none',
            background: dcolors.pallets.grey100, color: 'black', boxShadow: 'none', ...style
        }} variant="contained"
        href={href || ''}
        target="_blank"
        loadingPosition="start"
    >{text}</LoadingButton>
}