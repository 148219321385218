import * as Babel from '@babel/standalone';
import { useEffect, useState } from 'react';
import { DResponsiveWindow } from '../../libs/dweb/DResponsiveWindow';

export function TestPage() {
    return (
        <DResponsiveWindow width={window.innerWidth}>
            <LiveEditor />
        </DResponsiveWindow>)
}



// Component for the live editor
const LiveEditor = () => {
    // Initial code for the editor
    const [code, setCode] = useState(`
   function SampleComponent() {
      const [count, setCount] = React.useState(0);
      return (
        <div>
          <h1>Counter: {count}</h1>
          <button onClick={() => setCount(count + 1)}>Increment</button>
          <button onClick={() => setCount(count - 1)}>Decrement</button>
        </div>
      );
    }
  `);

    // State to store the transpiled output
    const [output, setOutput] = useState('');
    const [error, setError] = useState('');

    // Function to transpile the code using Babel
    const transpileCode = (code: any) => {
        try {
            const result = Babel.transform(code, {
                presets: ['react'],
            }).code;
            setOutput(result);
        } catch (err: any) {
            setError(err.message)
        }
    };

    // Effect to run the transpile function whenever the code changes
    useEffect(() => {
        transpileCode(`
            //const React = require('react');
            const { useState, useEffect } = React;
            ${code}
            ReactDOM.render(<SampleComponent />, document.getElementById('preview'));`);
    }, [code]);

    useEffect(() => {
        try {
            // Create a new function to run the code with React and ReactDOM in the scope
            const render = new Function('React', 'ReactDOM', output);
            // Render the transpiled code
            // @ts-ignore
            render(React, ReactDOM);
            setError('')
        } catch (err: any) {
            console.error(err);
            setError(err.message)
        }
    }, [output]);


    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flex: 1, padding: '10px' }}>
                <h3>React LIVE Editor</h3>
                <textarea
                    style={{ width: '100%', height: '90%' }}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
            </div>
            <div style={{ flex: 1, padding: '10px', borderLeft: '1px solid #ccc' }}>
                <h3>React LIVE Preview</h3>
                <div id="preview" />
                {error ? <pre>{error}</pre> : null}
            </div>
        </div>
    );
};

