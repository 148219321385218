import { dcolors, yahooFin } from "corexxx";
import { DCol } from "./DCol";
import { DText } from "./DText";

export function DChartCandleLtp({ data }: { data: yahooFin.TStockInfo }) {
    return <DCol>
        <DText style={{ color: getColor(data.change), fontWeight: 'bold', marginBottom: 4 }}>{data.ltp.toFixed(2)}, {data.change.toFixed(2)}({data.pchange.toFixed(2)}%)</DText>
        {data.pre_pchange ? <DText style={{ fontSize: 12, color: getColor(data.pre_pchange) }}>Pre:{data.pre_ltp?.toFixed(2)}({data.pre_pchange?.toFixed(2)}%)</DText> : null}
        {data.post_pchange ? <DText style={{ fontSize: 12, color: getColor(data.post_pchange) }}>Pre:{data.post_ltp?.toFixed(2)}({data.post_pchange?.toFixed(2)}%)</DText> : null}
    </DCol>
}

function getColor(num: number) {
    return num >= 0 ? dcolors.pallets.green600 : dcolors.pallets.red600
}