import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tooltip } from "@mui/material";
import { TStringCallback } from "corexxx";
import { CSSProperties } from "react";

export const DRadioButton = ({ style, value, onChange, options, label }: { style?: CSSProperties, options: { text: string, tooltip?: string }[], value: string, onChange: TStringCallback, label?: string, }) => {
    return (
        <FormControl component="fieldset" style={{ marginBottom: 10, ...style }}>
            {label ? <FormLabel component="legend">{label}</FormLabel> : null}
            <RadioGroup
                aria-label="options"
                name="options"
                value={value}
                row
                onChange={event => onChange(event.target.value)}
            >
                {options.map((x, idx) => <Tooltip title={x.tooltip || x.text}><FormControlLabel key={idx} style={{ textTransform: 'capitalize' }} value={x.text} control={<Radio />} label={x.text.replaceAll('_', " ") + ''} /></Tooltip>)}
            </RadioGroup>
        </FormControl>
    );
};