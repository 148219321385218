import { dcolors, TObject } from "corexxx"
import React, { useState } from "react"
import { useDAppCommand } from "../../../libs/dweb/DAppCommand"
import { DButton } from "../../../libs/dweb/DButton"
import { DInputString } from "../../../libs/dweb/DInputString"
import { DRow } from "../../../libs/dweb/DRow"
import { SSAlgoTradingDef } from "../../../libs/imported/SSAlgoTradingDef"
import { useAlgoVerse } from "../../Hook"


export function OneClickOrderFrom({ quoteData }: { quoteData: SSAlgoTradingDef.TLiveQuote }) {
    let hook = useAlgoVerse()
    let appCommand = useDAppCommand()

    let [loading1, setLoading1] = useState(false)
    let [loading2, setLoading2] = useState(false)

    let [input, setInput] = useState<TObject>({
        ticker: quoteData.symbol,
        limit_price: quoteData.mark_price,
        quantity: '0',
        order_type: 'LIMIT',
        session: 'NORMAL',
        stop_price: 0
    })

    React.useEffect(() => {
        setInput({ ...input, limit_price: quoteData.mark_price })
    }, [quoteData])

    async function placeOrder(action: 'BUY' | 'SELL') {
        let quantity = parseFloat(input.quantity)
        let order_payload: SSAlgoTradingDef.TSimpleOrderPayload = {
            ticker: input.ticker,
            quantity: quantity,
            limit_price: input.limit_price,
            stop_price: input.stop_price,
            order_type: input.order_type,
            action: action,
            'a_type': 'EQUITY',
            'account_number': hook.state.BrokerAccountInfo?.account_hash as string,
            session: input.session,
            duration: 'DAY'
        }
        await appCommand.api.safeCall(async () => {
            return await hook.api.submitSimpleOrder(order_payload);
        });
    }

    return (<>
        <DRow style={{ flex: 0, alignItems: 'center' }}>
            <DInputString style={{ marginRight: 10 }} value={input.quantity} onChange={x => setInput({ ...input, quantity: x })} label="Qty" />
            <DButton
                loading={loading1}
                style={{ margin: 10, background: dcolors.pallets.green700 }}
                text={`Buy ${input.quantity}  ${input.ticker} at ${input.limit_price} ?`}
                onClick={() => {
                    setLoading1(true);
                    placeOrder('BUY');
                    setLoading1(false)
                }} />
            <DButton
                loading={loading2}
                style={{ margin: 10, background: dcolors.pallets.red700 }}
                text={`Sell ${input.qty}  ${input.ticker} at ${input.limit_price} ?`}
                onClick={() => {
                    setLoading2(true);
                    placeOrder('SELL');
                    setLoading2(false)
                }} />
        </DRow>
    </>)
}