import { TObject, dcolors } from "corexxx"
import { useState } from "react"
import { DWebTS } from "../../../libs/common_ts/DWebTS"
import { useDAppCommand } from "../../../libs/dweb/DAppCommand"
import { DButton } from "../../../libs/dweb/DButton"
import { DInputString } from "../../../libs/dweb/DInputString"
import { DRow } from "../../../libs/dweb/DRow"
import { DSpace } from "../../../libs/dweb/DSpace"
import { DText } from "../../../libs/dweb/DText"
import { SSAlgoTradingDef } from "../../../libs/imported/SSAlgoTradingDef"
import { useAlgoVerse } from "../../Hook"
import { TCommonData } from "../TradePage"

//Conditional Order: One Triggers A One Cancels Another
// Buy 5 shares of XYZ at a Limit price of $14.97 good for the Day. Once filled, 2 sell orders are immediately sent: Sell 5 shares of XYZ at a Limit price of $15.27 and Sell 5 shares of XYZ with a Stop order where the stop price is $11.27.If one of the sell orders fill, the other order is immediately cancelled.Both Sell orders are Good till Cancel.Also known as a 1st Trigger OCO order.

export function OttOrderFrom({ quoteData, stq }: { quoteData: SSAlgoTradingDef.TLiveQuote, stq: TCommonData }) {
    let hook = useAlgoVerse()
    let [input, setInput] = useState<TObject>({ ticker: 'AAPL', buy_price: '0', quantity: '1', target_price: '0', stop_price: '0' })
    let [msg, setMsg] = useState<string | null>(null)
    let appCommand = useDAppCommand()
    async function placeOrder() {
        let order_payload: SSAlgoTradingDef.TOttOrderPayload = {
            account_number: hook.state.BrokerAccountInfo?.account_hash!!,
            ticker: input.ticker,
            quantity: parseFloat(input.quantity),
            session: input.session,

            buy_price: parseFloat(input.buy_price),
            target_price: parseFloat(input.target_price),
            stop_price: parseFloat(input.stop_price)
        }
        DWebTS.showConfirmationAndDo(async () => {
            await SSAlgoTradingDef.submitOttOrder(appCommand.accountState?.auth_token_!!, order_payload)
            await hook.api.loadBrokerAccountInfo()
        }, `BUY ${input.quantity} of ${input.ticker} with price limit ${input.buy_price} with target ${input.target_price} and SL ${input.stop_price} ?`)
    }
    return <><DRow style={{ flex: 0 }}>
        <DInputString
            style={{ marginRight: 10 }}
            label={'Buy Price'}
            value={input.buy_price}
            onChange={(e) => { setInput({ ...input, 'buy_price': e }) }}
        />
        <DInputString
            style={{ marginRight: 10 }}
            label={'Target Sell Price'}
            value={input.target_price}
            onChange={(e) => { setInput({ ...input, 'target_price': e }) }}
        />
        <DInputString
            style={{ marginRight: 10 }}
            label={'SL Sell Price'}
            value={input.stop_price}
            onChange={(e) => { setInput({ ...input, 'stop_price': e }) }}
        />
        <DSpace />
        <DButton onClick={placeOrder}
            text={`Place BUY=>TARGET->STOP Order`} style={{ justifySelf: 'flex-end', background: dcolors.pallets.green600 }}></DButton>
    </DRow>
        <DRow><DSpace />{msg ? <DText style={{ color: 'red', fontSize: 12 }}>({msg})</DText> : null}</DRow >
    </>
}