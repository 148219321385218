import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DHook } from '../../common_hooks/DHook';
import { useDAppCommand } from '../../dweb/DAppCommand';
import { DPageLoadingWrapper } from '../../dweb/DPageLoadingWrapper';
import { DResponsiveWindow } from '../../dweb/DResponsiveWindow';
import { DText } from '../../dweb/DText';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const REDIRECT_URL = `https://${window.location.hostname}${window.location.port.length > 0 ? ':' + window.location.port : ''}/oauth`
export function DefaultOAuthHandlerScreen() {
    const { provider } = useParams();
    const appCommand = useDAppCommand()
    const [success, setSuccess] = useState<boolean>(false)
    const query = useQuery();
    const code = query.get('code');

    const navigate = useNavigate();
    const handleRedirect = () => {
        setTimeout(() => {
            navigate('/dashboard');
        }, 500); // 5000 milliseconds = 5 seconds
    };
    DHook.useAsyncEffect(async () => {
        let red = `${REDIRECT_URL}/schwab`
        try {
            let result = await appCommand.api.postSimpleStore('/api/simple_auth/generate_oauth_tokens', {
                provider: provider?.toUpperCase(), code: code, redirect_uri: red
            })
            console.log(result)
            setSuccess(true);
            handleRedirect()
        } catch (e) {

        }

    }, [])

    return (
        <DResponsiveWindow>
            <DPageLoadingWrapper style={{ marginTop: 20 }}>
                {success ? <DText>Connected to {provider}. getting back to dashboard</DText> : <DText>Authenticating....</DText>}
            </DPageLoadingWrapper>
        </DResponsiveWindow>
    );
}