import { TObject, dcolors } from "corexxx"
import { useEffect, useState } from "react"
import { DWebTS } from "../../../libs/common_ts/DWebTS"
import { DButton } from "../../../libs/dweb/DButton"
import { DInputString } from "../../../libs/dweb/DInputString"
import { DRow } from "../../../libs/dweb/DRow"
import { DSelectInput } from "../../../libs/dweb/DSelectInput"
import { DSpace } from "../../../libs/dweb/DSpace"
import { DText } from "../../../libs/dweb/DText"
import { SSAlgoTradingDef } from "../../../libs/imported/SSAlgoTradingDef"
import { useAlgoVerse } from "../../Hook"
import { TCommonData } from "../TradePage"


export function SimpleOrderFrom({ quoteData, stq }: { quoteData: SSAlgoTradingDef.TLiveQuote, stq: TCommonData }) {
    let hook = useAlgoVerse()
    let [input, setInput] = useState<TObject>({
        limit_price: '0',
        action: 'BUY',
        order_type: 'LIMIT',
        stop_price: '0',
        ...stq
    })
    let [msg, setMsg] = useState<string | null>(null)
    let [new_avg, setNewAvg] = useState<string>(' ')

    useEffect(() => {
        setInput({ ...input, ...stq })
    }, [stq])

    useEffect(() => {
        let pos = hook.state.BrokerAccountInfo?.position_summary;
        if (!pos) {
            return;
        }
        if (input.action == 'BUY') {
            let avg = (pos.calculated_position_count * pos.calculated_today_average + parseFloat(input.quantity) * parseFloat(input.limit_price)) / (pos.calculated_position_count + parseFloat(input.quantity))
            let count = pos.calculated_position_count + parseFloat(input.quantity)
            if (avg.toFixed(2) != 'NaN') {
                setNewAvg(`New Avg: ${avg.toFixed(2)}, Inv: ${(avg * count).toFixed(2)} count:${count}`)
            } else {
                setNewAvg(' ')
            }
        }
    }, [input, hook.state.BrokerAccountInfo])

    async function placeOrder() {
        setMsg(null)
        let limit_price = parseFloat(input.limit_price)
        let stop_price = parseFloat(input.stop_price)
        let order_payload: SSAlgoTradingDef.TSimpleOrderPayload = {
            ticker: input.ticker,
            quantity: parseFloat(input.quantity),
            'account_number': hook.state.BrokerAccountInfo?.account_hash as string,
            session: input.session,
            duration: 'DAY',

            limit_price: limit_price,
            stop_price: stop_price,
            order_type: input.order_type,
            action: input.action,
            'a_type': 'EQUITY',

        }

        DWebTS.showConfirmationAndDo(async () => {
            try {
                await hook.api.submitSimpleOrder(order_payload);
            } catch (e: any) {
                setMsg(e.message)
            }

        }, `${input.action} ${input.qty} of ${input.ticker} with price ${limit_price} ?`)
    }
    return <><DRow style={{ flex: 0 }}>
        <DRow style={{ alignItems: 'center' }}>
            <DSelectInput
                style={{ marginRight: 10 }}
                label={'Action'}
                value={input.action}
                onChange={(e) => { setInput({ ...input, 'action': e }) }}
                options={[{ text: 'BUY', value: 'BUY' }, { text: 'SELL', value: 'SELL' }]}
            />
            <DSelectInput
                style={{ marginRight: 10, width: 120 }}
                label={'OrderType'}
                value={input.order_type}
                onChange={(e) => { setInput({ ...input, 'order_type': e }) }}
                options={[{ text: 'MARKET', value: 'MARKET' }, { text: 'LIMIT', value: 'LIMIT' }, { text: 'STOP', value: 'STOP' }, { text: 'STOP_LIMIT', value: 'STOP_LIMIT' }]}
            />
            {input.order_type == 'STOP' || input.order_type == 'STOP_LIMIT' ? <DInputString
                style={{ marginRight: 10 }}
                label={'Stop Price'}
                value={input.stop_price}
                onChange={(e) => { setInput({ ...input, 'stop_price': e }) }}
            /> : null}
            {input.order_type == 'LIMIT' || input.order_type == 'STOP_LIMIT' ? <DInputString
                style={{ marginRight: 10 }}
                label={'Limit Price'}
                value={input.limit_price}
                onChange={(e) => { setInput({ ...input, 'limit_price': e }) }}
            /> : null}
        </DRow>

        <div style={{ textAlign: 'right' }}>
            <DButton onClick={placeOrder}
                text={`${input.action} Order`} style={{ justifySelf: 'flex-end', background: input.action == 'BUY' ? dcolors.pallets.green600 : dcolors.pallets.red600 }}></DButton>
            <DText>{new_avg}</DText>
        </div>
    </DRow>
        <DRow><DSpace />{msg ? <DText style={{ color: 'red', fontSize: 12 }}>({msg})</DText> : null}</DRow >
    </>
}