
import { dcolors } from 'corexxx';
import { useEffect } from 'react';
import { CommonStock } from '../../libs/common_hooks/CommonStock';
import { useDInterval } from '../../libs/dhook/useDInterval';
import { useDAppCommand } from '../../libs/dweb/DAppCommand';
import { DPageRootLayoutWithDrawer } from '../../libs/dweb/DPageRootLayout';
import { DRefreshButton } from '../../libs/dweb/DRefreshButton';
import { SSAlgoTradingDef } from '../../libs/imported/SSAlgoTradingDef';
import { useAlgoVerse } from '../Hook';
import { BrokerPage } from './BrokerPage';
import { HypothesisTesting } from './HypothesisTesting';
import { MarketPage } from './MarketPage';
import { MistakePage } from './MistakePage';
import { PerformancePage } from './PerformancePage';
import { TradePage } from './TradePage';

export function DashBoard() {
    let hook = useAlgoVerse()
    let appCommand = useDAppCommand()
    let stockCommand = CommonStock.useStock()

    useDInterval(async () => {
        hook.api.loadQuoteData();
    }, 5 * 1000, [appCommand.state.auth_token_])

    useDInterval(async () => {
        hook.api.loadBrokerAccountInfo();
    }, 30 * 1000, [appCommand.state.auth_token_])

    useEffect(() => {
        SSAlgoTradingDef.setEndpoint(appCommand.state.simpleStoreEndpoint)
        hook.api.loadQuoteData()
        hook.api.loadBrokerAccountInfo();
        stockCommand.api.init()
    }, [])

    useEffect(() => {
        SSAlgoTradingDef.setEndpoint(appCommand.state.simpleStoreEndpoint)
    }, [appCommand.state.simpleStoreEndpoint])

    return (
        <DPageRootLayoutWithDrawer
            bodyStyle={{ backgroundColor: dcolors.pallets.yellow50 }}
            config={{
                title: 'Grodok AlgoVerse',
                appBarConfig: {
                    appBarRightJSX: <DRefreshButton style={{ color: 'white' }} onClick={hook.api.loadBrokerAccountInfo} />
                }
            }}
            drawerOpen={true}
            drawerPreset={{
                drawerWidth: 300,
                headerText: "Grodok AlgoVerse",
                footerText: "DSoftware @2022",
                drawerBackground: "#e7e7e7",
                color: "black",
                menuList: [
                    {
                        name: '⏰ Market',
                        screen: <MarketPage />
                    },
                    {
                        name: '🔆 Trade',
                        screen: <TradePage />
                    },
                    {
                        name: '📊 Performance',
                        screen: <PerformancePage />
                    },
                    {
                        name: '📒 Settings',
                        screen: <BrokerPage />
                    },
                    {
                        name: '📚 Hypothesis Testing',
                        screen: <HypothesisTesting />
                    },
                    {
                        name: '💡 Mistakes & Learning',
                        screen: <MistakePage />
                    },
                ]
            }}
        ></DPageRootLayoutWithDrawer >)
}