import { useEffect, useState } from "react";

interface TimeBarProps {
    preMarketStart: string;
    normalMarketStart: string;
    normalMarketEnd: string;
    postMarketEnd: string;
}
// This will show a timebar as showing in Trading212
export const DChartTimeBar: React.FC<TimeBarProps> = ({ preMarketStart, normalMarketStart, normalMarketEnd, postMarketEnd }) => {
    const [currentTime, setCurrentTime] = useState<Date>(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const timeToMinutes = (time: string): number => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const getPercentage = (time: string): number => {
        const totalMinutes = timeToMinutes(postMarketEnd) - timeToMinutes(preMarketStart);
        const elapsedMinutes = timeToMinutes(time) - timeToMinutes(preMarketStart);
        return (elapsedMinutes / totalMinutes) * 100;
    };

    const preMarketWidth = getPercentage(normalMarketStart);
    const normalMarketWidth = getPercentage(normalMarketEnd) - preMarketWidth;
    const postMarketWidth = 100 - preMarketWidth - normalMarketWidth;

    const currentTimePosition = getPercentage(
        `${currentTime.getHours()}:${currentTime.getMinutes().toString().padStart(2, '0')}`
    );

    return (
        <div style={{ position: 'relative', height: 6, overflow: 'hidden', width: '100%', display: 'flex' }}>
            <div style={{ width: `${preMarketWidth}%`, height: '100%', backgroundColor: '#FFD700', display: 'inline-block' }}></div>
            <div style={{ width: `${normalMarketWidth}%`, height: '100%', backgroundColor: '#32CD32', display: 'inline-block' }}></div>
            <div style={{ width: `${postMarketWidth}%`, height: '100%', backgroundColor: '#1E90FF', display: 'inline-block' }}></div>
            <div style={{
                position: 'absolute',
                left: `${currentTimePosition}%`,
                top: 0,
                bottom: 0,
                width: 6,
                backgroundColor: 'black',
            }}></div>
        </div>
    );
};
