import { dcolors } from "corexxx";
import { CSSProperties } from "react";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DSpace } from "./DSpace";

export function DChartStockBar({ data, style }: { style?: CSSProperties, data: { ltp: number, low: number, high: number, open: number, close: number, pclose: number } }) {
    let left = (data.ltp - data.low) / (data.high - data.low) * 100;
    let pleft = ((data.pclose || 0) - data.low) / (data.high - data.low) * 100;
    if (pleft < 0) { pleft = -4 }
    if (pleft > 100) { pleft = 104 }
    let vol = (data.high - data.low) / data.low * 100
    return <DCol style={{ maxWidth: 200, ...style }}>
        <DRow style={{ marginBottom: 5 }}>
            <span>PC:{data.pclose?.toFixed(2)}</span>
            <DSpace></DSpace>
            <span>LTP:{data.ltp.toFixed(2)}</span>
        </DRow>
        <DRow style={{ position: 'relative' }}>
            <div style={{ flex: 1, marginRight: 5, background: dcolors.pallets.grey300, height: 5 }}></div>
            <div style={{ flex: 1, marginRight: 5, background: dcolors.pallets.grey300, height: 5 }}></div>
            <div style={{ flex: 1, marginRight: 5, background: dcolors.pallets.grey300, height: 5 }}></div>
            <div style={{ flex: 1, background: dcolors.pallets.grey300, height: 5 }}></div>
            <div style={{ background: 'red', position: 'absolute', left: left + '%', top: 0, width: 6, height: 6 }} />
            <div style={{ background: 'blue', position: 'absolute', left: pleft + '%', top: 0, width: 6, height: 6 }} />
        </DRow>
        <DRow style={{ marginTop: 5 }}>
            <span>L:{data.low.toFixed(2)}</span>
            <DSpace />
            <span>{vol.toFixed(2)}%</span>
            <DSpace />
            <span>H:{data.high.toFixed(2)}</span>
        </DRow>
    </DCol>
}
