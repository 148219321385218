import { CSSProperties, useEffect, useState } from 'react';
import { DText } from './DText';

export function DClock({ style }: { style?: CSSProperties }) {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formatTime = (date: Date): string => {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const weekDay = daysOfWeek[date.getDay()];
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${weekDay}[${hours}:${minutes}:${seconds}]`;
    };

    return (
        <DText style={style}>
            {formatTime(currentTime)}
        </DText>
    );
};

