import React, { useState } from "react";
import { useDAppCommand } from "../../libs/dweb/DAppCommand";
import { DBox } from "../../libs/dweb/DBox";
import { DButton } from "../../libs/dweb/DButton";
import { DCol } from "../../libs/dweb/DCol";
import { DInputString } from "../../libs/dweb/DInputString";
import { DRow } from "../../libs/dweb/DRow";
import { DSelectInput } from "../../libs/dweb/DSelectInput";
import { DSpace } from "../../libs/dweb/DSpace";
import { DTable } from "../../libs/dweb/DTable";
import { DText } from "../../libs/dweb/DText";
import { YahooFinHelper } from "../../libs/imported/ExportedTypeDefinaton";
import { BasePageNavigation } from "../App";

export function HypothesisTesting() {
    let appComamnd = useDAppCommand()
    let [ticker, setTicker] = useState('AAPL');
    let [interval, setInterval] = useState('5m');
    let [range, setRange] = useState('1mo')
    let [data, setData] = useState<YahooFinHelper.TCandleDataByDate[]>([])

    async function fetchData() {
        let res = await appComamnd.api.getSimpleStore(`${appComamnd.state.simpleStoreEndpoint}/api/utils/yahoo_fin/historical_data?symbol=${ticker}&interval=${interval}&range=${range}`);
        setData(res.out)
    }

    return (
        <BasePageNavigation>
            <DBox>
                <DRow>
                    <DInputString value={ticker} onChange={setTicker} label="Ticker" />
                    <DSelectInput style={{ marginLeft: 10 }} label="Interval" value={interval} onChange={setInterval} options={[{ text: '5m', value: '5m' }, { text: '15m', value: '15m' }, { text: '1d', value: '1d' }]} />
                    <DSpace />
                    <DButton onClick={fetchData} text="Fetch Data" loading={appComamnd.state.is_loading} />
                </DRow>
            </DBox>
            <DBox style={{ marginTop: 20 }}>
                <DRow>
                    <DCol>
                        <DText style={{ marginBottom: 10 }}>Raw Data</DText>
                        <DTable
                            style={{ height: innerHeight - 500, overflowY: 'auto', border: 0 }}
                            data={data.reverse()} columns={[
                                { field: 'date' },
                                { field: 'high', format: (arg, obj) => `${(parseFloat(arg)).toFixed(2)}` },
                                { field: 'low', format: (arg, obj) => `${(parseFloat(arg)).toFixed(2)}` },
                                { field: 'open', format: (arg, obj) => `${(parseFloat(arg)).toFixed(2)}` },
                                { field: 'close', format: (arg, obj) => `${(parseFloat(arg)).toFixed(2)}` },
                                { field: 'volume' },
                                { field: 'percentChange', format: (arg, obj) => `${(parseFloat(arg)).toFixed(2)}%` }
                            ]} />
                    </DCol>
                    <DCol>

                    </DCol>
                </DRow>
            </DBox>
        </BasePageNavigation>)
}

export function VolumeData({ data }: { data: YahooFinHelper.TCandleDataByDate[] }) {
    let [data1, setData1] = React.useState<TMatrixData>([])
    React.useEffect(() => {
        let val: TMatrixData = []
        data.forEach(r => {
            let row: { date: string, candles: { target: number }[] } = { date: r.date + '', candles: [] }
            r.candles.forEach(c => {
                row.candles.push({ target: c.percentChange })
            })
            val.push(row)
        })
        setData1(val)
    }, [data])


    return (<DInformationContainer header="Volume Matrix">
        <DCandlestickMatrix data={data1} />
    </DInformationContainer>)
}

export function DInformationContainer({ header, children }: { header: string, children?: any }) {
    return <DCol style={{ padding: 10, marginTop: 10, background: 'white', border: '1px solid black' }}>
        <DRow style={{ flex: 0, marginBottom: 10 }}>
            <DText style={{ flex: 1, fontWeight: 'bolder', textTransform: 'capitalize', fontSize: '1.3rem', color: 'black', }}>{header}</DText>
        </DRow>
        {children}
    </DCol>
}

export type TMatrixData = { date: string, candles: { target: number }[] }[]
const DCandlestickMatrix = ({ data }: { data: TMatrixData }) => {
    const volumes: number[] = [];
    data.forEach(day => {
        day.candles.forEach(candle => {
            volumes.push(candle.target);
        });
    });
    const maxVolume = Math.max(...volumes);
    const minVolume = Math.min(...volumes);
    //debugger
    const getColor = (volume: number) => {
        const intensity = (volume - minVolume) / (maxVolume - minVolume);
        return `rgba(255, 0, 0, ${intensity})`;
    };

    const matrixStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column'
    };

    const rowStyle: React.CSSProperties = {
        display: 'flex'
    };

    const cellStyle: React.CSSProperties = {
        width: '20px',
        height: '20px',
        margin: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '10px',
        color: 'white',
        border: '1px solid #ddd'
    };

    return (
        <div style={matrixStyle}>
            {data.map((day, rowIndex) => (
                <div style={rowStyle} key={rowIndex}>
                    <div
                        style={{ ...cellStyle, backgroundColor: 'white', minWidth: 120, color: 'black', fontSize: 8 }}
                        key={'1'}
                        title={`${day.date}`}
                    >
                        {getWeekdayName(new Date(day.date))}
                    </div>
                    {day.candles.map((candle, colIndex) => (
                        <div
                            style={{ ...cellStyle, backgroundColor: getColor(candle.target) }}
                            key={colIndex}
                            title={`Volume: ${candle.target}`}
                        >
                            {candle.target}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

const getWeekdayName = (date: Date): string => {
    return date.toLocaleDateString('en-US', { weekday: 'long' }) + date.toLocaleDateString();
};