import { AddCircle } from "@mui/icons-material";
import { DButton } from "../../libs/dweb/DButton";
import { DPageHeader } from "../../libs/dweb/DPageHeader";
import { DThirdPartyOAuth } from "../../libs/dweb/DThirdPartyOAuth";
import { BasePageNavigation } from "../App";

export function BrokerPage() {
    return (
        <BasePageNavigation>
            <DPageHeader title="Connected Brokers" rightView={<DButton text="Add Broker" icon={<AddCircle />} onClick={() => { }} />} />
            <DThirdPartyOAuth />
        </BasePageNavigation>)
}