import { dtime } from "corexxx";
import { CSSProperties, useState } from "react";
import { DAlert } from "../../libs/dweb/DAlert";
import { useDAppCommand } from "../../libs/dweb/DAppCommand";
import { DBox } from "../../libs/dweb/DBox";
import { DCol } from "../../libs/dweb/DCol";
import { DInputString } from "../../libs/dweb/DInputString";
import { DRadioButton } from "../../libs/dweb/DRadioButton";
import { DRow } from "../../libs/dweb/DRow";
import { DSelectInput } from "../../libs/dweb/DSelectInput";
import { BasePageNavigation } from "../App";
import { useAlgoVerse } from "../Hook";
import { OrderTable, PositionTable } from "./Common";
import { AnalysisView } from "./common/AnalysisView";
import { OcoOrderFrom } from "./orders/OcoOrderFrom";
import { OneClickOrderFrom } from "./orders/OneClickOrderFrom";
import { OtoOrderFrom } from "./orders/OtoOrderFrom";
import { OttOrderFrom } from "./orders/OttOrderFrom";
import { SimpleOrderFrom } from "./orders/SimpleOrderFrom";
import { SummaryBox } from "./orders/SummaryBox";
import { TrailingStopOrderFrom } from "./orders/TrailingStopLossOrderFrom";
import { AlgoVerseEmptyView } from "./PerformancePage";
export type TCommonData = { session: string, ticker: string, quantity: string }
export function TradePage() {
    let hook = useAlgoVerse()
    let appCommand = useDAppCommand()
    let [order_type, set_order_type] = useState<string>('simple')
    let [stq, setSTQ] = useState<TCommonData>({ session: 'NORMAL', 'ticker': hook.state.focusTicket || 'AAPL', quantity: '0' })

    if (!hook.state.BrokerAccountInfo) {
        return <AlgoVerseEmptyView />
    }

    const getForm = () => {
        switch (order_type) {
            case 'simple':
                return <SimpleOrderFrom quoteData={hook.state.liveQuote[hook.state.focusTicket]} stq={stq} />
            case 'OTT':
                return <OttOrderFrom quoteData={hook.state.liveQuote[hook.state.focusTicket]} stq={stq} />
            case 'OTO':
                return <OtoOrderFrom quoteData={hook.state.liveQuote[hook.state.focusTicket]} stq={stq} />
            case 'OCO':
                return <OcoOrderFrom quoteData={hook.state.liveQuote[hook.state.focusTicket]} stq={stq} />
            case 'trailing_stop':
                return <TrailingStopOrderFrom quoteData={hook.state.liveQuote[hook.state.focusTicket]} stq={stq} />
            default:
                return <SimpleOrderFrom quoteData={hook.state.liveQuote[hook.state.focusTicket]} stq={stq} />
        }
    }
    let sectionStyle: CSSProperties = {
        marginTop: 20
    }

    return (
        <BasePageNavigation>
            {hook.state.liveQuote[hook.state.focusTicket] ? (<DRow>
                <DCol style={{ paddingRight: 20 }}>
                    <DBox style={{ flex: 0, padding: 0 }}>
                        <SummaryBox quoteData={hook.state.liveQuote[hook.state.focusTicket]} />
                    </DBox>
                    <DBox style={{ flex: 0 }}>
                        <OneClickOrderFrom quoteData={hook.state.liveQuote[hook.state.focusTicket]} />
                    </DBox>
                    <DBox style={{ flex: 0, marginTop: 20, marginBottom: 20 }}>

                        <DRow style={{ marginBottom: 20, }}>
                            <DSelectInput
                                style={{ marginRight: 10 }}
                                label={'Session'}
                                value={stq.session}
                                options={[{ text: 'NORMAL', value: 'NORMAL' }, { text: 'AM', value: 'AM' }, { text: 'PM', value: 'PM' }, { text: 'SEAMLESS', value: 'SEAMLESS' }]}
                                onChange={(e) => { setSTQ({ ...stq, 'session': e }) }}
                            />
                            <DInputString
                                style={{ marginRight: 10 }}
                                label={'Ticker'}
                                value={stq.ticker}
                                onChange={(e) => { setSTQ({ ...stq, 'ticker': e }) }}
                            />
                            <DInputString
                                style={{ marginRight: 10 }}
                                label={'Quantities'}
                                value={stq.quantity + ''}
                                onChange={(e) => { setSTQ({ ...stq, 'quantity': e }) }}
                            />
                        </DRow>
                        <DRadioButton
                            style={{ marginBottom: 20 }}
                            value={order_type}
                            options={[
                                { text: 'simple', tooltip: "Simple buy or Sell" }, { text: 'trailing_stop', tooltip: "Tralling stop lokss" },
                                { text: 'OTO', tooltip: " Buy on limit and then sell on limit" },
                                { text: 'OTT', tooltip: " Buy on limit and then sell on limit or stop loss" },
                                { text: 'OCO', tooltip: "We already hold, share, sell on limit or sell on stoploss" }]}
                            onChange={e => set_order_type(e)} />
                        {getForm()}
                    </DBox>
                    <DBox style={{ flex: 0, padding: 0 }}>
                        <PositionTable />
                    </DBox>

                    <DBox style={{ flex: 0, padding: 0, marginTop: 20 }}>
                        <OrderTable orders={hook.state.BrokerAccountInfo?.order_information.filter(x => dtime.isSameDay(x.tracker_time || '', new Date().toISOString())) || []} status1="WORKING" />
                    </DBox>

                    <DBox style={{ marginTop: 20, flex: 0, }}>
                        <AnalysisView quote={hook.state.liveQuote[hook.state.focusTicket]} />
                    </DBox>
                </DCol>
            </DRow>) : <DAlert notification={appCommand.state.notification || { type: 'error', "msg": hook.state.error || 'something wrong' }}></DAlert>}
        </BasePageNavigation>)
}




