import { OnePager } from "../../libs/components/simpleonepager/OnePager";
import { PageHeaderMenu } from "../../libs/dweb/DPageHeaderMenu";

export function LandingPage() {
    return <OnePager
        extraElement={<PageHeaderMenu logo={require('../asserts/img/logo.png')} rightDropDownActions={[{ text: 'dashboard', href: '/dashboard' }]} style={{ backgroundColor: '#00000000' }} />}
        config={{
            hero_section: {
                hero_title: 'Welcome to Grodok AlgoVerse',
                hero_subtitle: 'A unified OMS and Market Analysis tool',
                hero_image_src: '',
                primary_btn_link: '/',
                primary_btn_subtext: 'Please fill-up the form if you want to use SimpleStore API"s',
                primary_btn_title: "Let's us know "
            },
            generic_section: [
                {
                    groupOfThree: [{
                        title: '100+ Global supported APIs',
                        subtitle: 'It covers from CRUD, Auth, SSO, payment etc',
                        btn_text: 'Show me all API',
                        btn_link: 'https://simplestore.dipankar.co.in/api/'
                    },
                    {
                        title: '99% reliabilities Matrix',
                        subtitle: 'All APIs are supported by e2e automation testes ',
                        btn_text: 'Learn readability Matrix',
                        btn_link: 'https://simplestore.dipankar.co.in/api/'
                    },
                    {
                        title: "Build-in Operations API's ",
                        subtitle: 'Build-in support for logging, analytics, crash logs and SEV Managers ',
                        btn_text: 'Known operation',
                        btn_link: 'https://simplestore.dipankar.co.in/api/'
                    }
                    ]
                },

                {
                    groupOfThree: [{
                        title: 'Powerful Sign-Up API',
                        subtitle: 'password, token, magic link, social and SSO login',
                        btn_text: 'know more',
                        btn_link: 'https://simplestore.dipankar.co.in/api/'
                    },
                    {
                        title: 'Payment APIs',
                        subtitle: 'Supported international payment gateways',
                        btn_text: 'Learn readability Matrix',
                        btn_link: 'https://simplestore.dipankar.co.in/api/'
                    },
                    {
                        title: "Simplified Database API's",
                        subtitle: 'CRUD and filtering is much powerful with SimpleStore ',
                        btn_text: 'Known operation',
                        btn_link: 'https://simplestore.dipankar.co.in/api/'
                    }
                    ]
                },
            ],
            footer_section: {
                author_email: 'dsofthelp@outlook.com',
                author_text: 'Dipankar',
                author_link: '/',
                privacy_link: '/',
                toc_link: '/'
            }
        }
        } />
}

