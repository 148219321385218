import { IconButton } from "@mui/material"
import { REDIRECT_URL } from "../components/screens/DefaultOAuthHandler"
import { DRow } from "./DRow"
export const SCHWAP_APP_KEY = "BfQwxJGANBot1jWGO4e4LUuVjP33ckXs" // https://developer.schwab.com/dashboard/apps/app/58ef54f5-e4d0-4c46-ac92-bebc1c5e2137
const UPSTOX_CLIENT_ID = '09af93f0-dd36-462f-a50d-9b58d6cbbf34'
export function DThirdPartyOAuth() {
    return <DRow>
        <IconButton href={`https://api.schwabapi.com/v1/oauth/authorize?client_id=${SCHWAP_APP_KEY}&redirect_uri=${REDIRECT_URL}/schwab`}>
            <img src="https://developer.schwab.com/assets/schwab-logo.svg" />
        </IconButton>
    </DRow>

}