import { dcolors } from 'corexxx';
import React from 'react';
import { DRow } from './DRow';
import { DText } from './DText';

// Define the types for the component's props
interface DChartHorizantalBarWithPointsProps {
    points: number[];
    now: number;
}

const DChartHorizantalBarWithPoints: React.FC<DChartHorizantalBarWithPointsProps> = ({ points, now }) => {
    // Styling for the bar and the dots
    const barStyle: React.CSSProperties = {
        flex: 1,
        height: '6px',
        marginRight: 10,
        backgroundColor: dcolors.pallets.grey300,
    };

    const dotStyle = (position: number, color: string): React.CSSProperties => ({
        position: 'absolute',
        left: `${position}%`,
        top: 0,
        transform: 'translateX(-50%)',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: color,
    });

    const textStyle = (position: number): React.CSSProperties => ({
        position: 'absolute',
        left: `${position}%`,
        transform: 'translateX(-50%)',
        marginTop: '8px',
        color: 'black',
        whiteSpace: 'nowrap',
    });

    const percentageStyle = (position: number): React.CSSProperties => ({
        position: 'absolute',
        left: `${position}%`,
        transform: 'translateX(-50%)',
        paddingTop: '30px',
        color: 'black',
        fontSize: 12,
        whiteSpace: 'nowrap',
    });

    // Function to calculate percentage positions of the points
    const calculatePosition = (value: number, min: number, max: number): number => {
        if (max === min) return 0;
        return ((value - min) / (max - min)) * 100;
    };

    // Function to calculate the percentage distance from `now`
    const calculatePercentageFromNow = (value: number, now: number): string => {
        if (now === value) return '0%';
        return `${((value - now) / (now === 0 ? 1 : now) * 100).toFixed(2)}%`;
    };

    // Determine the min and max values from the points
    const min = Math.min(...points);
    const max = Math.max(...points);

    return (
        <div style={{ width: '100%', height: 50, }}>
            <div style={{ position: 'relative' }}>
                <DRow>
                    <div style={barStyle} />
                    <div style={barStyle} />
                    <div style={barStyle} />
                    <div style={{ ...barStyle, marginRight: 0 }} />
                </DRow>
                {points.map((point, index) => (
                    <React.Fragment key={index} >
                        <div style={dotStyle(calculatePosition(point, min, max), 'blue')} />
                        <DText style={textStyle(calculatePosition(point, min, max))}>{point}</DText>
                        <DText style={percentageStyle(calculatePosition(point, min, max))}>
                            {calculatePercentageFromNow(point, now)}
                        </DText>
                    </React.Fragment>
                ))}
                <div style={dotStyle(calculatePosition(now, min, max), 'red')} />
                <DText style={textStyle(calculatePosition(now, min, max))}>{now}</DText>
                <DText style={percentageStyle(calculatePosition(now, min, max))}>0%</DText>
            </div>
        </div>
    );
};

export default DChartHorizantalBarWithPoints;
