import { dcolors } from "corexxx"
import React, { useState } from "react"
import { useDAppCommand } from "../../../libs/dweb/DAppCommand"
import { DChartTimeBar } from "../../../libs/dweb/DChartTimeBar"
import { DClock } from "../../../libs/dweb/DClock"
import { DCol } from "../../../libs/dweb/DCol"
import { DDropDownMenu } from "../../../libs/dweb/DDropdownMenu"
import { DRow } from "../../../libs/dweb/DRow"
import { DSpace } from "../../../libs/dweb/DSpace"
import { DText } from "../../../libs/dweb/DText"
import { DTextDashboardItem } from "../../../libs/dweb/DTextDashboard"
import { SSAlgoTradingDef } from "../../../libs/imported/SSAlgoTradingDef"
import { useAlgoVerse } from "../../Hook"

export function SummaryBox({ quoteData }: { quoteData: SSAlgoTradingDef.TLiveQuote }) {
    let hook = useAlgoVerse()
    let appCommand = useDAppCommand()
    let [quote, setQuote] = useState<SSAlgoTradingDef.TLiveQuote>()

    React.useEffect(() => {
        setQuote(hook.state.liveQuote[hook.state.focusTicket])
    }, [hook.state.liveQuote])

    async function restartEngine() {
        let res = await appCommand.api.getSimpleStore(appCommand.state.simpleStoreEndpoint + '/api/algo_trade_engine/restart')
    }

    if (!hook?.state?.BrokerAccountInfo) {
        return <></>
    }

    let quoteStyle = { paddingLeft: 10, paddingRight: 10, fontSize: 14, lineHeight: '34px', color: 'white', border: 5 }

    return (<DCol>
        <DRow style={{ padding: 30, background: dcolors.pallets.grey800 }}>
            <DTextDashboardItem dark text="name" value={hook.state.BrokerAccountInfo.account_name} sub_text={'acc:' + hook.state.BrokerAccountInfo.account_number} />
            <DTextDashboardItem dark text="Starting Capital" value={hook.state.BrokerAccountInfo.portfolio_summary.initial_amount.toFixed(2) + ''} />
            <DTextDashboardItem dark text="Current Amount" value={hook.state.BrokerAccountInfo.portfolio_summary.current_amount.toFixed(2) + ''} />
            <DTextDashboardItem dark text="Invested" value={hook.state.BrokerAccountInfo.portfolio_summary.initial_amount.toFixed(2) + ''} />
            <DTextDashboardItem dark text="Cash" value={hook.state.BrokerAccountInfo.portfolio_summary.cash_amount.toFixed(2) + ''} />
            <DTextDashboardItem dark text="P/L" value={hook.state.BrokerAccountInfo.portfolio_summary.total_return_str + ''} />
            <DDropDownMenu actions={[
                { text: 'Reload', onClick: hook.api.loadBrokerAccountInfo },
                { text: 'Restart Algo Engine', onClick: restartEngine }
            ]} />

        </DRow>
        {quote ? (
            <DRow style={{ marginTop: 0 }}>
                <DText style={{ background: dcolors.pallets.red900, ...quoteStyle }}>{quoteData.symbol}</DText>
                <DText style={{ background: dcolors.pallets.green600, ...quoteStyle }}>Ask: {quoteData.ask_price}/{quoteData.ask_size}</DText>
                <DText style={{ background: dcolors.pallets.red700, ...quoteStyle }}>Bid: {quoteData.bid_price}/{quoteData.bid_size}</DText>
                <DText style={{ background: dcolors.pallets.blue700, ...quoteStyle }}>Mark: {quoteData.mark_price}</DText>
                <DText style={{ background: dcolors.pallets.grey700, ...quoteStyle }}>Change: {quoteData.netChange.toFixed(2)}({quoteData.netPercentChange.toFixed(2)}%)</DText>
                <DSpace />
                <DClock style={{ background: dcolors.pallets.red700, ...quoteStyle }} />
            </DRow>) : null}
        <DChartTimeBar
            preMarketStart="09:00"
            normalMarketStart="14:30"
            normalMarketEnd="19:00"
            postMarketEnd="24:00" />
    </DCol>)
}