import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { dtime } from 'corexxx';
import React, { useState } from "react";
import { DChipActionList } from "../../libs/dweb/DChipActionList";
import { DCol } from "../../libs/dweb/DCol";
import { DRow } from "../../libs/dweb/DRow";
import { DTextDashboard } from '../../libs/dweb/DTextDashboard';
import { DTextEmptyView } from '../../libs/dweb/DTextEmptyView';
import { SSAlgoTradingDef } from '../../libs/imported/SSAlgoTradingDef';
import { BasePageNavigation } from "../App";
import { useAlgoVerse } from "../Hook";
import { OrderTable } from './Common';

export function AlgoVerseEmptyView() {
    let hook = useAlgoVerse();
    if (hook.state.error) {
        return <DTextEmptyView title="⚠️ Something went wrong! ⚠️ " subtitle={'😢 ' + hook.state.error} />
    } else {
        return <DTextEmptyView title="Loading information, please wait..." subtitle={'Please click the refresh button on the menu bar if it takes longer to load'} />
    }
}

function buildDate(dateStr: string) {
    return new Date(dateStr.split('/').reverse().join('-')).toISOString()
}

// some orders need to be ignored as a sell order is not retuned by the API - there must be some issue 
const IGNORE_ORDER = ['1000927655444']

export function PerformancePage() {
    let hook = useAlgoVerse();
    let [orderData, setOrderData] = React.useState<SSAlgoTradingDef.TOrderOrganizedDataByWeek | undefined>()
    let [weekData, setWeekData] = useState<SSAlgoTradingDef.TOrderOrganizedDataByDay | undefined>();

    // Override use to ofset the date so that we have no overlapping orders
    let [dateOverride, setDateOverride] = useState<SSAlgoTradingDef.TDateOverride>({
        // They must not be sat or sun day
        '1000986341403': buildDate('12/07/2024'),
        '1000999675439': buildDate('15/07/2024'),
        '1000859840748': buildDate('27/06/2024'),
        '1000801909840': buildDate('24/06/2024'),
        '1000884313785': buildDate('01/07/2024')
    })


    React.useEffect(() => {
        if (hook.state.BrokerAccountInfo?.order_information) {
            let data = SSAlgoTradingDef.organizeOrderDataByWeekAndDay(hook.state.BrokerAccountInfo?.order_information)
            //debugger
            if (Object.keys(data).length > 0) {
                setOrderData(data)
                setWeekData(Object.values(data)[0])
            }
        }
    }, [hook.state.BrokerAccountInfo?.order_information])

    if (!orderData) {
        return <AlgoVerseEmptyView />
    }
    return (<BasePageNavigation>
        <DCol>
            <DRow style={{ flex: 0, marginBottom: 30 }}>
                <DChipActionList
                    actions={Object.keys(orderData || []).sort((b: string, a: string) => a.toLowerCase().localeCompare(b.toLowerCase())).map(x => ({ text: x, onClick: () => { setWeekData(orderData?.[x]) } }))} />
            </DRow>
            {weekData ? <WeekView data={weekData} /> : null}
        </DCol>
    </BasePageNavigation>)
}

export function WeekView({ data }: { data: SSAlgoTradingDef.TOrderOrganizedDataByDay }) {
    return <DCol>
        {Object.keys(data).map(date => {
            let total_buy = data[date]?.filter(x => x.status == 'FILLED' && x.action == 'BUY').reduce((acc, num) => acc + num.quantity * num.fill_price, 0);
            let total_sell = data[date]?.filter(x => x.status == 'FILLED' && x.action == 'SELL').reduce((acc, num) => acc + num.quantity * num.fill_price, 0);


            let total_buy_qn = data[date]?.filter(x => x.status == 'FILLED' && x.action == 'BUY').reduce((acc, num) => acc + num.quantity, 0);
            let total_sell_qn = data[date]?.filter(x => x.status == 'FILLED' && x.action == 'SELL').reduce((acc, num) => acc + num.quantity, 0);


            let filled = data[date]?.filter(x => x.status == 'FILLED')
            let filled_buy = data[date]?.filter(x => x.status == 'FILLED' && x.action == 'BUY')
            let filled_sell = data[date]?.filter(x => x.status == 'FILLED' && x.action == 'SELL')
            let canceled = data[date]?.filter(x => x.status == 'CANCELED')
            let riskMove = data[date]?.filter(x => !dtime.isSameDay(x.tracker_time, x.entered_time)).length

            return <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <DTextDashboard item={[
                        {
                            text: 'date',
                            value: date.toUpperCase(),
                            sub_text: data[date].length > 0 ? `${dtime.getDateOnlyFromISO(data[date][0].tracker_time || '')}` : ''
                        },
                        {
                            text: 'Filled Trade/Total Trade',
                            value: `${filled.length}/${data[date].length}`,
                            sub_text: `Buy: ${filled_buy.length}/Sell: ${filled_sell.length}/Cancel: ${canceled.length}`,
                        },
                        {
                            text: 'Quantities Roll Over',
                            value: `${total_buy_qn - total_sell_qn}`,
                            sub_text: `bought: ${total_buy_qn} units & sold: ${total_sell_qn} units`,
                        },
                        {
                            text: 'Gain',
                            value: `${(total_sell - total_buy).toFixed(2)}(${total_buy > 0 ? ((total_sell - total_buy) / total_buy * 100).toFixed(2) : '0'}%)`,
                            sub_text: `Buy: ${total_buy.toFixed(2)}, Sell: ${total_sell.toFixed(2)}`
                        },
                        {
                            text: 'Efficiency(GoC)',
                            value: `${((total_sell - total_buy) / 40000 * 100).toFixed(2)}%`,
                            sub_text: `${riskMove > 0 ? '⚠️' : ''} Risk Move: ${riskMove}`,

                        }
                    ]} />

                </AccordionSummary>
                <AccordionDetails>
                    <OrderTable orders={data[date]} status1='ALL' />
                </AccordionDetails>
            </Accordion>
        })}
    </DCol>
}