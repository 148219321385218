import { useEffect, useRef } from "react";

// execute a functions in every 10 sec
export function useDInterval(callback: () => void, delayMs: number, dependencies: any[] = []) {
    const savedCallback = useRef<() => void>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
        // callback();
    }, [callback, ...dependencies]);


    // Set up the interval.
    useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }

        if (delayMs !== null) {
            const id = setInterval(tick, delayMs);
            return () => clearInterval(id);
        }
    }, [delayMs, ...dependencies]);
}