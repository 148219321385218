import { dcolors, TObject, yahooFin } from "corexxx";
import { useState } from "react";
import { CommonStock } from "../../libs/common_hooks/CommonStock";
import { useDAppCommand } from "../../libs/dweb/DAppCommand";
import { DChartCandleLtp } from "../../libs/dweb/DChartCandleLtp";
import { DChartStockBar } from "../../libs/dweb/DChartStockBar";
import { DChip } from "../../libs/dweb/DChip";
import { DMarquee } from "../../libs/dweb/DMarquee";
import { DPageHeader } from "../../libs/dweb/DPageHeader";
import { DRow } from "../../libs/dweb/DRow";
import { DSearchButton } from "../../libs/dweb/DSearchButton";
import { DSelectInput } from "../../libs/dweb/DSelectInput";
import { DTable } from "../../libs/dweb/DTable";
import { BasePageNavigation } from "../App";
import { useAlgoVerse } from "../Hook";
import { AlgoVerseEmptyView } from "./PerformancePage";

export function MarketPage() {
    let hook = useAlgoVerse()
    let stockCommand = CommonStock.useStock()
    let appComamnd = useDAppCommand()
    let [exchange, setExchange] = useState('NASDAQ')
    let [order_type, set_order_type] = useState<string>('simple')
    if (!hook.state.BrokerAccountInfo) {
        return <AlgoVerseEmptyView />
    }
    return (
        <BasePageNavigation>
            <DMarquee text={stockCommand.state.indexList?.map(x => `<span style="color:${x.pchange >= 0 ? 'green' : 'red'}">${x.name}(${x.pchange.toFixed(2)}%)</span>`)?.join('   |   ')}
                style={{
                    background: dcolors.pallets.yellow300, color: 'white',
                    paddingTop: 6, paddingBottom: 6, whiteSpace: 'pre'
                }} />
            <DPageHeader title="Market Today" rightView={(<DRow>
                <DChip style={{ marginRight: 10 }} text="Refresh" onClick={() => { stockCommand.api.init() }} loading={appComamnd.state.notification?.is_loading} />
                <DChip style={{ marginRight: 10 }} text="Calender" href="https://www.fxstreet.com/economic-calendar" />
                <DChip style={{ marginRight: 10 }} text="News" href="https://www.marketwatch.com/" />
                <DChip style={{ marginRight: 10 }} text="Fed Watch" href="https://www.cmegroup.com/markets/interest-rates/cme-fedwatch-tool.html" />
                <DChip style={{ marginRight: 10 }} text="PreMarket" href="https://www.benzinga.com/premarket" />
                <DChip text="Earning" href="https://www.benzinga.com/calendars/earnings" />
                <DSearchButton
                    onSearch={async (text) => {
                        await stockCommand.api.addWatch(text)
                    }}
                    btn_text="Add Watch"
                    style={{ marginLeft: 20, marginRight: 20 }}
                />
                <DSelectInput label="exchange" value={exchange} onChange={setExchange} options={stockCommand.state.exchnageList.map(x => ({ text: x, value: x }))} />
            </DRow>)} />
            <DTable
                height={window.innerHeight - 100}
                style={{ boxShadow: 'none' }}
                data={stockCommand.state.watchList[exchange] || []} columns={[
                    { field: 'symbol', sortFn: (a, b) => a.symbol > b.symbol ? 1 : -1 },
                    {
                        headerName: "Price",
                        field: "ltp",
                        formatEx: (arg: string, obj: TObject) => <DChartCandleLtp data={obj as yahooFin.TStockInfo} />,
                        sortFn: (a, b) => a.pchange > b.pchange ? 1 : -1
                    },
                    {
                        headerName: "Price Move",
                        field: 'pchange',
                        formatEx: (arg, obj: any) => <DChartStockBar data={{
                            high: obj.high,
                            low: obj.low,
                            open: obj.open,
                            close: obj.close,
                            ltp: obj.ltp,
                            pclose: obj.previousClose || 0
                        }} />,
                        sortFn: (a, b) => a.pchange > b.pchange ? 1 : -1
                    },
                    {
                        headerName: "Volume ",
                        field: 'volume',
                        format(arg, obj) {
                            return `${obj.volume} (${((obj.volume - obj.avgVolume) / obj.avgVolume).toFixed(2)}%)`
                        },
                        sortFn: (a, b) => {
                            return ((a.volume - a.avgVolume) / a.avgVolume) > ((b.volume - b.avgVolume) / b.avgVolume) ? 1 : -1
                        }
                    }
                ]}
                inline_buttons_actions={[
                    {
                        text: 'News',
                        onClick: (obj, idx) => {
                            appComamnd.api.openNewTab('https://www.marketwatch.com/investing/stock/' + obj.symbol)
                        }
                    },
                    {
                        text: 'Delete',
                        style: { background: dcolors.pallets.red900, color: 'white' },
                        onClick: (data) => stockCommand.api.removeWatch(data.symbol)
                    }
                ]}
            />

        </BasePageNavigation>)
}





