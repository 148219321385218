import { CircularProgress } from "@material-ui/core"
import { dcolors } from "corexxx"
import { CSSProperties } from "react"
import { useDAppCommand } from "./DAppCommand"
import { DCol } from "./DCol"
import { DHtml } from "./DHtml"
import { DRow } from "./DRow"
import { DText } from "./DText"

// A great illustartion with image, title and subtitle text
export const DIllustrationHelper1 = ({ loading, title, subtitle, image, style, points }: { loading?: boolean, title: string, subtitle?: string, image?: any, style?: CSSProperties, points?: string[] }) => {
    let appCommand = useDAppCommand()
    return (
        <DRow style={{ flex: 0, alignItems: 'center', justifyContent: 'center', ...style }}>
            <img src={image || require('../img/loading.png')} style={{ flex: 0 }} />
            <DCol style={{ flex: 1, justifyContent: 'center', margin: 80 }}>
                <DText style={{ marginBottom: 40, fontWeight: 'bold', fontSize: 24, color: dcolors.pallets.grey800, textAlign: 'left' }}>{title}</DText>
                {subtitle ? <DHtml style={{ fontSize: 15, color: dcolors.pallets.grey500, lineHeight: 1.5, textAlign: 'left' }} html={subtitle} /> : null}
                {points?.map(x => <DHtml style={{ fontSize: 20, color: dcolors.pallets.green600, textAlign: 'left' }} html={'✓ &nbsp;  ' + x} />)}
            </DCol>
            {loading ? <CircularProgress style={{ color: appCommand.state.appConfig?.primary_color || 'black' }} /> : null}
        </DRow >)
}

export const DIllustrationHelper = ({ loading, title, subtitle, image, style, points }: { loading?: boolean, title: string, subtitle?: string, image?: any, style?: CSSProperties, points?: string[] }) => {
    let appCommand = useDAppCommand()
    return (<DCol>
        <DText>{loading ? <CircularProgress style={{ color: appCommand.state.appConfig?.primary_color || 'black' }} /> : null} {title}</DText>
        <DText>{subtitle}</DText>
    </DCol>)
}
