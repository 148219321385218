import { dcolors, TObject } from "corexxx";
import React, { useState } from "react";
import { DBox } from "../../libs/dweb/DBox";
import { DChip } from '../../libs/dweb/DChip';
import { DRow } from "../../libs/dweb/DRow";
import { DSelectInput } from "../../libs/dweb/DSelectInput";
import { DText } from "../../libs/dweb/DText";
import { DTextAreaButton } from "../../libs/dweb/DTextAreaButton";
import { SSUtils } from "../../libs/imported/SSUtils";
import { BasePageNavigation } from "../App";
import { useAlgoVerse } from "../Hook";

const COLOR: TObject = {
    'Mistake': dcolors.pallets.red400,
    'Fear': dcolors.pallets.amber700,
    'Greed': dcolors.pallets.amber400,
    'DoMore': dcolors.pallets.green400,
    'Advice': dcolors.pallets.green400,
}

const ICON: TObject = {
    'Mistake': '❌',
    'Greed': '🤑',
    'Fear': '😱',
    'DoMore': '💬',
    'Advice': '✅',
}

export function MistakePage() {
    let hook = useAlgoVerse()
    let [type, setType] = useState('Mistake')
    React.useEffect(() => {
        hook.api.loadLearning()
    }, [])

    return (
        <BasePageNavigation>
            <DBox>
                <DRow center style={{ flex: 0, marginBottom: 10, }}>
                    <DText mode="title" style={{ flex: 1 }}>Your list of Feelings</DText>
                    <DSelectInput
                        label="Select a type"
                        style={{ alignSelf: 'flex-end', width: 200 }}
                        value={type} onChange={setType} options={[
                            { text: 'Mistake', value: 'Mistake' },
                            { text: 'Fear', value: 'Fear' },
                            { text: 'Greed', value: 'Greed' },
                            { text: 'DoMore', value: 'DoMore' },
                            { text: 'Advice', value: 'Advice' }
                        ]}></DSelectInput>
                </DRow>
                <DTextAreaButton
                    btn_text="Submit"
                    onSearch={(text) => {
                        hook.api.addLearning(type, text)
                    }} />
            </DBox>
            <DBox style={{ marginTop: 20 }}>
                {hook.state.learning.map(x => {
                    return <DText style={{ paddingBottom: 20 }}>
                        <DChip text={`${ICON[x.type]} ${x.type}`} style={{ marginRight: 10, color: 'black', background: COLOR[x.type] || dcolors.pallets.blue700 }}></DChip>{SSUtils.toHumanReadFormatDate(x.ts_insert)}, {x.message}
                    </DText>
                })}
            </DBox>
        </BasePageNavigation >)
}

