import { dlog, dnetwork, yahooFin } from "corexxx";
import React, { useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import { DWebTS } from "../common_ts/DWebTS";
import { useDAppCommand } from "../dweb/DAppCommand";

export namespace CommonStock {
    const ROOT_FOCUS_URL = 'https://simplestore.dipankar.co.in/api/remote_nodel_focus_list';
    const ROOT_WATCH_URL = 'https://simplestore.dipankar.co.in/api/remote_nodel_watch_list';
    const MARKET_ENDPOINT = "https://simplestore.dipankar.co.in/api/utils/trade"

    export type FocusItemHistory = {
        action: 'buy' | 'sell';
        count: number;
        price: number;
        result?: number;
    };

    export type TFocusItem = {
        key: string;
        _id?: string;
        user_id: string;
        count: number;
        average: number;
        history: Array<FocusItemHistory>;
        stockInfo: yahooFin.TStockInfo;
        [type: string]: any;
        ffd?: { amount: number; depth: number; rate: number; initial_ltp: number };
    };

    let dummayStockInfo: yahooFin.TStockInfo = {
        _id: '',
        ltp: 0,
        low: 0,
        high: 0,
        change: 0,
        pchange: 0,
        symbol: '',
        open: 0,
        name: '',
        exchange: 'NASDAQ',
        volume: 0,
        timestamp: 0,
    };

    export type TWatchList = { [key: string]: yahooFin.TStockInfo[] }

    const INDEX_SYMBOL_LIST = ['^SPX', 'ES=F', '^VIX']

    const { persistAtom } = recoilPersist();
    const watchAtom = atom<TWatchList>({
        key: "stock_watch",
        default: {},
        effects_UNSTABLE: [persistAtom],
    });

    const indexAtom = atom<yahooFin.TStockInfo[]>({
        key: "stock_index",
        default: [],

    });
    const exchangeListAtom = atom<string[] | []>({
        key: "exchangeListAtom",
        default: [],
        effects_UNSTABLE: [persistAtom],
    });

    let watch_id = ''
    export const useStock = () => {
        let appCommand = useDAppCommand();
        let [loading, setLoading] = useState(false)
        let [watchList, setWatchList] = useRecoilState(watchAtom);
        let [indexList, setIndexList] = useRecoilState(indexAtom);
        let [exchnageList, setExchangeList] = useRecoilState(exchangeListAtom)

        const reload = React.useCallback(async () => {
            setLoading(true)
            let res0 = await dnetwork.postSimpleStore(ROOT_WATCH_URL, { user_id: appCommand.accountState?.username })
            if (res0.out.length == 0) {
                setLoading(false)
                return;
            }
            let symbols = res0.out[0].symbols
            symbols = [...INDEX_SYMBOL_LIST, ...symbols]
            watch_id = res0.out[0]._id;
            const stcokres = await dnetwork.getSimpleStore(MARKET_ENDPOINT + "/ltp?symbols=" + symbols.join(","));
            let data = stcokres.out as yahooFin.TStockInfo[]
            data.sort((a, b) => a.pchange > b.pchange ? -1 : 1)

            let watchListRes: TWatchList = { 'all': [] }
            let indexs: yahooFin.TStockInfo[] = []
            let set = new Set<string>()
            data.forEach(x => {
                if (!watchListRes[x.exchange.toString()]) {
                    watchListRes[x.exchange.toString()] = []
                }
                watchListRes[x.exchange.toString()].push(x)
                set.add(x.exchange.toString())
                watchListRes['all'].push(x)
                if (INDEX_SYMBOL_LIST.indexOf(x._id) != -1) {
                    indexs.push(x)
                }
            })
            setWatchList(watchListRes)
            setIndexList(indexs)
            setExchangeList(Array.from(set))
            setLoading(false)

        }, [])

        return {
            api: {
                init: () => {
                    dlog.d("merit project is getting inited");
                    reload();
                },
                reload: reload,
                addWatch: async (s: string) => {
                    await appCommand.api.safeCall(async () => {
                        await dnetwork.postSimpleStore(`${ROOT_WATCH_URL}/tags/add`, {
                            id: watch_id,
                            _field: 'symbols',
                            _value: s,
                        })
                    }, "watch added", "not able to add watch")
                    await reload()
                },
                removeWatch: async (s: string) => {
                    await DWebTS.showConfirmationAndDo(async () => {
                        await appCommand.api.safeCall(async () => {
                            await dnetwork.postSimpleStore(`${ROOT_WATCH_URL}/tags/remove`, {
                                id: watch_id,
                                _field: 'symbols',
                                _value: s,
                            })
                        }, "watch removed", "not able to remove watch")
                        await reload()
                    })
                }
            },
            state: {
                exchnageList: exchnageList,
                watchList: watchList,
                indexList: indexList,
            }
        }
    }
}