import { TObject, dcolors } from "corexxx"
import { useEffect, useState } from "react"
import { DWebTS } from "../../../libs/common_ts/DWebTS"
import { useDAppCommand } from "../../../libs/dweb/DAppCommand"
import { DButton } from "../../../libs/dweb/DButton"
import { DInputString } from "../../../libs/dweb/DInputString"
import { DRow } from "../../../libs/dweb/DRow"
import { DSpace } from "../../../libs/dweb/DSpace"
import { DText } from "../../../libs/dweb/DText"
import { SSAlgoTradingDef } from "../../../libs/imported/SSAlgoTradingDef"
import { useAlgoVerse } from "../../Hook"
import { TCommonData } from "../TradePage"

//Conditional Order: One Triggers A One Cancels Another
// Buy 5 shares of XYZ at a Limit price of $14.97 good for the Day. Once filled, 2 sell orders are immediately sent: Sell 5 shares of XYZ at a Limit price of $15.27 and Sell 5 shares of XYZ with a Stop order where the stop price is $11.27.If one of the sell orders fill, the other order is immediately cancelled.Both Sell orders are Good till Cancel.Also known as a 1st Trigger OCO order.

export function TrailingStopOrderFrom({ quoteData, stq }: { quoteData: SSAlgoTradingDef.TLiveQuote, stq: TCommonData }) {
    let hook = useAlgoVerse()
    let appCommand = useDAppCommand()

    let [input, setInput] = useState<TObject>({ stop_price_offset: '0', ...stq })
    let [msg, setMsg] = useState<string | null>(null)

    useEffect(() => {
        setInput({ ...input, ...stq })
    }, [stq])

    async function placeOrder() {
        let order_payload: SSAlgoTradingDef.TTrailingStopOrderPayload = {
            account_number: hook.state.BrokerAccountInfo?.account_hash!!,
            session: input.session,
            quantity: parseFloat(input.quantity),
            ticker: input.ticker,

            stop_price_offset: input.stop_price_offset,
        }
        DWebTS.showConfirmationAndDo(async () => {
            await appCommand.api.postSimpleStore('/api/utils/schwab/submit_trailing_stop_order', {
                order_payload: order_payload
            })
            await hook.api.loadBrokerAccountInfo()
        }, `SELL ${input.quantity} of ${input.ticker} with price SL ${input.stop_price_offset}?`)
    }
    return <><DRow style={{ flex: 0 }}>
        <DInputString
            style={{ marginRight: 10 }}
            label={'Stop Price Offset (absolute value)'}
            value={input.stop_price_offset}
            onChange={(e) => { setInput({ ...input, 'stop_price_offset': e }) }}
        />
        <DSpace />
        <DButton onClick={placeOrder}
            text={'Place Sell Order'} style={{ justifySelf: 'flex-end', background: dcolors.pallets.red500 }}></DButton>
    </DRow>
        <DRow><DSpace />{msg ? <DText style={{ color: 'red', fontSize: 12 }}>({msg})</DText> : null}</DRow >
    </>
}