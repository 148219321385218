import { dcolors } from 'corexxx';
import { CSSProperties } from "react";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DText } from "./DText";
export const DTextTwoKeyValue = ({ style, items }: { items: { key: string, value: string, subtext?: string, style?: CSSProperties }[]; style?: CSSProperties }) => {
    return (
        <DRow style={style}>
            {items.map((x, idx) => {
                return <DCol key={idx} style={{ background: dcolors.pallets.grey50, padding: 10, marginRight: 20, textAlign: 'center' }}>
                    <DText style={{}}>{x.key}</DText>
                    <DText style={{ fontWeight: 'bold', fontSize: 20, ...style }}>{x.value}</DText>
                    {x.subtext ? <DText style={{ fontSize: 12 }}>{x.subtext}</DText> : null}
                </DCol>
            })}
        </DRow>
    );
};